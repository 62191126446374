// Import React and image files
import React from "react";
import "./Footer.css";
import Github from "../../assets/github.png";
import Instagram from "../../assets/instagram.png";
import LinkedIn from "../../assets/linkedin.png";
import Logo from "../../assets/logo.png";

// Define Footer component
const Footer = () => {
  // Return JSX to render footer section
  return (
    <div className="Footer-container">
      {/* Display a horizontal line (<hr />) that serves as a visual separator */}
      <hr />
      <div className="footer">
        {/* Display social media icons */}
        <div className="social-links">
          <img src={Github} alt="" />
          <img src={Instagram} alt="" />
          <img src={LinkedIn} alt="" />
        </div>
        {/* Display logo */}
        <div className="logo-f">
          <img src={Logo} alt="" />
          <footer className="f-copyright" >Built with &#9829; Samuel Afolabi | All rights reserved.</footer>
        </div>
      </div>
      {/* Add blur effects to footer background */}
      <div className="blur footer-blur-1"></div>
      <div className="blur footer-blur-2"></div>
    </div>
  );
};

// Export Footer component
export default Footer;
